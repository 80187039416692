import React, { Component } from "react";
import Layout from "../../components/layout/layout.js";
import Module from "../../components/structure/Module/module.js";
import Wrapper from "../../components/structure/Wrapper/wrapper.js";
import { Title } from "../../components/elements/Title/title.js";
import RichText from "../../components/modules/RichText/richText";
import Seo from "../../components/seo/seo.js";
import Category from '../../components/modules/Category/category';
import BlogItem from '../../components/modules/Blog/blogItem';
import Column from '../../components/structure/Column/column';
import {Opinions} from "../../components/modules/Opinions/opinions";
import { Button } from '../../components/elements/Button/button';

class Home extends Component {

    render() {
        const seo = this.props.pageContext.seo;
        const mainText = this.props.pageContext.mainText;
        const textStatic = this.props.pageContext.textStatic;
        const mainTitle = this.props.pageContext.mainTitle;
        const dataType = this.props.pageContext.dataType;
        const country_code = seo.country_code;
        const blog =  this.props.pageContext.blog;

        const types_big = dataType.filter(function (el) {
            return el.is_main === 1;
        });

        const types_small = dataType.filter(function (el) {
            return el.is_main !== 1;
        });

        return (
            <Layout>
                <Seo lang={seo.lang} title={seo.title} url={seo.url} description={seo.description}  robotsIndex={seo.robotsIndex} robotsFollow={seo.robotsFollow}/>
                <Module>
                    <Wrapper>
                        <Title tag="h1" size24 content={mainTitle} className="text-align--center" />

                        <Column className='marg-t30' row>
                            {types_big.map((value, index) => (
                                <Column col='third' colSm='half' colXs='full'>
                                    <Category big
                                              title={value.name}
                                              subtitle={value.description}
                                              img={value.image}
                                              link={value.page}
                                    />
                                </Column>
                            ))}
                        </Column>
                        <Column className='marg-t30' row>
                            {types_small.map((value, index) => (
                                <Column col='third' colSm='half' colXs='full'   >
                                    <Category small
                                              title={value.name}
                                              subtitle={value.description}
                                              icon={value.icon}
                                              link={value.page}
                                    />
                                </Column>
                            ))}
                        </Column>
                    </Wrapper>

                    {blog &&
                        <Wrapper>
                            <Column row>
                                <Title size20 tag="h2" content={textStatic.blog_title_home} className="text-align--center marg-b32" />
                                {blog.map((value, index) => (
                                    <Column col='third' colSm='half' colXs='full'>
                                        <BlogItem item={value} textStatic={textStatic}/>
                                    </Column>
                                ))}
                            </Column>
                            <Column centered>
                                <Button onClickFunction={function(){}}  link={"/blog/"} content={textStatic.more_info}/>
                            </Column>
                        </Wrapper>
                    }

                    <Wrapper middle>
                        <Column>
                            <RichText content={mainText} />
                            <Title size20 tag="h2" content={textStatic.opinions_title} className="text-align--center marg-t60" />
                        </Column>
                        <Opinions
                            textStatic={textStatic}
                            seo={seo}
                            country_code={country_code}
                            count={10}
                            typePage={"home"}
                        />
                    </Wrapper>
                </Module>
            </Layout>
        );
    }
}

export default Home;