import React from 'react';
import getClassName from './helperRating';
import "./rating.scss";
import classNames from "classnames";

export const Stars = props => {
    const classnames = classNames("m-rating-stars", {
        "m-rating-stars--small": props.small,
    });
    const countStars = props.countStars;
    const productRating = props.productRating;
    return (
        <div className={classnames}>
            {[...Array(countStars)].map((event, i) =>
                <i
                    data-count={i+1}
                    className={'icon-rating user_vote ' + getClassName(i+1, productRating)}
                    aria-hidden="true"
                />

            )}
        </div>
    );
}