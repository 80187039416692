import React, {useEffect, useState} from 'react';
import "./opinions.scss";
import { Text } from "../../elements/Text/text";
import { Title } from "../../elements/Title/title";
import Column from '../../structure/Column/column';
import { Stars } from '../Rating/stars';
import { LinkBtn } from '../../elements/LinkBtn/linkBtn';
import {Button} from "../../elements/Button/button";

export const Opinions = props => {

    const count = props.count || 5;
    const pagination = props.pagination || false;
    const country_code = props.country_code;
    const typePage = props.typePage;

    const object_id = props.object_id || false;
    const type_id = props.type_id || false;
    const region_1_slug = props.region_1_slug || false;
    const region_2_slug = props.region_2_slug || false;
    const region_3_slug = props.region_3_slug || false;
    const textStatic = props.textStatic;
    const defaultCurrentSourceName = props.defaultCurrentSourceName || false;
    let defaultCurrentSourceUrl = props.defaultCurrentSourceUrl || false;

    if (defaultCurrentSourceUrl) {
        defaultCurrentSourceUrl = defaultCurrentSourceUrl.substr(0, defaultCurrentSourceUrl.length - 1)
    }

    const domain = props.seo.host;
    const [comments, setComments] = useState(false);
    const [totalRating, setTotalRating] = useState(false);
    const [totalCount, setTotalCount] = useState(false);
    const [isReady, setIsReady] = useState(false);
    const [countLoad, setCountLoad] = useState(pagination);


    useEffect(() => {

        getRating(country_code, type_id, object_id, region_1_slug, region_2_slug, count);

    }, [country_code, object_id]);

    return (
        <div className={"m-opinions"}>

            {isReady && (
                <>
                    <Column row className={`marg-b32 marg-t20`}>

                        {comments.map(function(d, index) {

                            let display = !pagination || index < countLoad;

                            return (
                                OpinionItem(
                                    d.rating,
                                    d.author && d.author.length ? d.author : textStatic.opinions_default_author,
                                    d.comment && d.comment.length ? d.comment : textStatic.opinions_default_comment,
                                    d.source_name && d.source_name.length ? d.source_name : defaultCurrentSourceName,
                                    d.source_url && d.source_url.length ? (domain + d.source_url) : defaultCurrentSourceUrl,
                                    typePage,
                                    display
                                )
                            );
                        })}

                    </Column>

                    {
                        pagination && countLoad < comments.length &&

                        <Column centered>
                            <Button onClickFunction={() => loadMore()} content={textStatic.opinions_load_comments}/>
                        </Column>
                    }

                </>
            )}

        </div>
    );

    function loadMore() {

        setCountLoad(countLoad + 20);
    }

    function getRating(country_code, type_id, object_id, region_1_slug, region_2_slug, count) {

        const apiUrl = object_id ? "get-rating" : "get-last-comments";

        const requestOptions = {
            method: 'POST',
            body: JSON.stringify({
                country_code: country_code ,
                object_id: object_id,
                type_id: type_id,
                region_1_slug: region_1_slug,
                region_2_slug: region_2_slug,
                region_3_slug: region_3_slug,
                count: count,
                comments: true,
            })
        };

        fetch('https://allgym.work/' + apiUrl, requestOptions)
            .then(response => response.json())
            .then(data => {

                setComments(data.comments);
                setTotalRating(data.rating);
                setTotalCount(data.count);
                setIsReady(true);
            });
    }
};

const OpinionItem = (rating, author, content, source_name, source_url, typePage, display) => {

    const typesHideLink = ["object"];
    const isShowLink = !!source_url && !typesHideLink.includes(typePage);
    const displayClass = display ? "" : "d-none";

    return (
        <Column row bgWhite className={`m-opinions--item ${displayClass}`}>
            <div className={`m-opinions--item-head`}>
                <div className={`m-opinions-name-rating`}>
                    <span className={`m-opinions-name`}>{author}</span>
                    <div className={"m-rating"}>
                        <Stars small countStars={5} productRating={parseInt(rating)} />
                    </div>
                </div>
                
                {isShowLink &&
                    <span className={`m-opinions-opinion`}>
                        <LinkBtn middle to={source_url.substr(0, source_url.length - 1)} content={source_name} />
                    </span>
                }

            </div>
            <div className={`m-opinions--item-body`}>
                <Text content={content} />
            </div>
        </Column>
    )
};
