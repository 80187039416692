import React from 'react';
import classNames from 'classnames';
import { Title } from '../../elements/Title/title';
import { Text } from '../../elements/Text/text';
import './category.scss';
import IconArrow from "../../elements/Icon/iconArrow";

const Category = props => {

    const classnames = classNames('m-category', {
        'm-category--big': props.big,
        'm-category--small': props.small,
    });

    return(
        <div className={props.className ? props.className + ` ` + classnames : classnames}>
            <a href={props.link}>
                {props.big && <img src={props.img} />}
                <div className='m-category__text'>
                    <div className='m-category__text-info'>
                        <Title className="marg-t0" size16 content={props.title} />
                        <Text size13 content={props.subtitle} />
                    </div>
                    {props.icon && <i className={`icon-gym ${props.icon}`}/>}
                </div>
            </a>
        </div>
    );
}

export default Category;